import { graphql } from "gatsby";
import React, { useMemo, useRef, useState } from "react";
import Layout from "../../containers/Layout";
import SEO from "../../components/Seo";
import UpComingWebinar from "../../components/Webinars/UpComingWebinar";
import WebinarSeries from "../../components/Webinars/WebinarSeries";
import EmbeddedCta from "../../components/Blog/EmbeddedCta";
import { nav } from "../../utils/navigation";
import DropdownSelector from "../../components/DropdownSelector";
import { marketplaces } from "../../constants/filters";
import Search from "../../images/search.svg";
import { EVENT_CATEGORY } from "../../utils/webinars";
import { BreadcrumbPageType, formatBreadcrumb } from "../../utils/breadcrumb";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";

interface WebinarsProps {
  data: any;
  pageContext: any;
}

const Webinars: React.FC<WebinarsProps> = ({ pageContext, data }) => {
  const contentfulData = data.allContentfulBizzaboWebinars.nodes;

  const [currentType, setCurrentType] = useState<string>("");
  const [activeMarketplace, setActiveMarketplace] = useState<string>("");
  const pageData = pageContext?.content;

  const oldEvents = [
    { id: 423134, category: ["target:amazon", "series:education"] },
    { id: 426417, category: ["target:amazon", "series:education"] },
    { id: 421672, category: ["target:amazon", "series:education"] },
    { id: 435275, category: ["target:amazon", "series:education"] },
    { id: 440295, category: ["target:amazon", "series:education"] },
    { id: 445325, category: ["target:amazon", "series:masterclass"] },
    { id: 454770, category: ["target:amazon", "series:masterclass"] }
  ];


  // check pageData item id is in oldEvents if yes add category to pageData item
  const finalEvents = pageData?.map((item: any) => {
    const oldEvent = oldEvents.find(
      oldEvent => oldEvent.id === item.id && item.id !== null
    );
    if (oldEvent) {
      if (!item.category) {
        item.category = oldEvent.category;
      }
    }
    return item;
  });

  const upcomingWebinar = finalEvents.reduce((a: any, b: any) => {
    return new Date(a.endDate) > new Date(b.endDate) ? a : b;
  });
  console.log("upcomingWebinar", upcomingWebinar);

  const sections = [
    {
      title: EVENT_CATEGORY.MASTERCLASS,
      bgGradient: "bg-gradient-to-b via-white from-indigo-3-light",
      color: "text-indigo-3-dark",
      content: finalEvents.filter((webinar: any) => {
        return webinar?.category?.includes("series:masterclass");
      }),
      contentfulData: contentfulData.filter(
        webinar => webinar.category[0] === EVENT_CATEGORY.MASTERCLASS
      )
    },
    {
      title: EVENT_CATEGORY.IN_THE_LOOP,
      bgGradient: "bg-gradient-to-b via-white from-blue-3",
      color: "text-blue-1",
      content: finalEvents.filter((webinar: any) => {
        return webinar?.category?.includes("series:in-the-loop");
      }),
      contentfulData: contentfulData.filter(
        webinar => webinar.category[0] === EVENT_CATEGORY.IN_THE_LOOP
      )
    },
    {
      title: EVENT_CATEGORY.THE_EXCHANGE,
      bgGradient: "bg-gradient-to-b via-white from-green-3",
      color: "text-green-3-dark",
      content: finalEvents.filter((webinar: any) => {
        return webinar?.category?.includes("series:the-exchange");
      }),
      contentfulData: contentfulData.filter(
        webinar => webinar.category[0] === EVENT_CATEGORY.THE_EXCHANGE
      )
    },
    {
      title: EVENT_CATEGORY.EDUCATION,
      bgGradient: "bg-gradient-to-b via-white from-gray-5-light",
      color: "text-gray-3-dark",
      content: finalEvents.filter((webinar: any) => {
        return webinar?.category?.includes("series:education");
      }),
      contentfulData: contentfulData.filter(
        webinar => webinar.category[0] === EVENT_CATEGORY.EDUCATION
      )
    }
  ];

  console.log("sections", sections);

  const sectionRefs = sections.map(() => useRef(null));
  const scrollToSection = index => {
    sectionRefs[index].current.scrollIntoView({ behavior: "smooth" });
  };

  const updatedCrumbs = useMemo(() => formatBreadcrumb(pageContext, ""), []);

  return (
    <Layout>
      <SEO title={""} description={""} />
      <div className="max-w-8xl mx-auto mt-48 px-6 sm:px-12 lg:px-4">
        <CustomBreadcrumb crumbs={updatedCrumbs} />
      </div>
      <UpComingWebinar data={upcomingWebinar} />
      <div className="my-7 bg-white max-w-8xl px-6 sm:px-12 lg:px-4 mx-auto flex justify-between">
        <div className="flex">
          {sections.map((section: any, index: number) => {
            if (section?.contentfulData?.length > 0) {
              return (
                <div
                  key={index}
                  className="text-gray-1 bg-gray-5-light text-sm px-3 py-2 rounded-large font-semibold lg:mr-6 cursor-pointer"
                  onClick={() => {
                    scrollToSection(index);
                  }}
                >
                  {section.title}
                </div>
              );
            }
          })}
        </div>
        <div className="relative">
          <Search className="absolute top-1/2 left-3 -translate-y-1/2" />
          <input
            className="h-full w-auto pl-10 py-2 text-base rounded-lg border border-gray-4 focus:outline-none focus:border-indigo-1"
            placeholder="Search"
            value={currentType}
            onInput={e => setCurrentType(e.currentTarget.value)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                // setSearchQuery(currentType);
                nav(`/blog/search?s=${currentType}`);
                // setCurrentType("");
              }
            }}
          />
        </div>

        <DropdownSelector
          activeFilter={activeMarketplace}
          filters={marketplaces}
          setActiveFilter={setActiveMarketplace}
        />
      </div>
      {sections.map((section: any, index: number) => {
        if (section?.contentfulData?.length > 0) {
          return (
            <WebinarSeries
              key={index}
              sectionRef={sectionRefs[index]}
              title={section.title}
              bgGradient={section.bgGradient}
              color={section.color}
              data={section.content}
              contentfulData={section.contentfulData}
            />
          );
        }
      })}
      <div className="max-w-8xl px-6 sm:px-12 lg:px-4 mx-auto">
        <EmbeddedCta
          fullWidth
          buttonText={"Sign Up"}
          title={"Insights to help grow profit"}
          textFieldPlaceholder={"Email address"}
          successText={
            "Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
          }
        />
      </div>
    </Layout>
  );
};

export default Webinars;

export const query = graphql`
  query BizzaboEvents {
    allContentfulBizzaboWebinars(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            url
            gatsbyImageData
          }
          robots
        }
        webinarTitle
        eventId
        registrationFormId
        eventVimeoVideo
        category
      }
    }
  }
`;
