import React, { useMemo, useState } from "react";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { useSegment } from "../../../utils/analytics";
import { Category, Name, Type } from "../../../utils/analytics/constants";
import { nav } from "../../../utils/navigation";
import Link from "../../Link";
import RightArrow from "../../../images/right-arrow.svg";
import cn from "classnames";
import DateAndTime from "../../DateAndTime";
import { webinarSlug } from "../../../utils/webinars";
import WatchOnDemand from "../WatchOnDemand";
import TargetAndSeries from "../TargetAndSeries";

interface WebinarSeriesProps {
  title: string;
  bgGradient: string;
  color: string;
  data: any;
  contentfulData: any;
  sectionRef: any;
}

const WebinarSeries: React.FC<WebinarSeriesProps> = props => {
  const {
    data,
    title,
    bgGradient = "bg-gradient-to-b from-indigo-3-light",
    color = "text-gray-3-dark",
    sectionRef,
    contentfulData
  } = props;
  const { helpers } = useSegment();

  //merge in on array contentful data and bizzabo----
  const webinarData = data?.map((item: any) => {
    const contentfulEvent = contentfulData?.find(
      (event: any) => event?.eventId == item?.id
    );
    return { ...item, contentfulEvent };
  });

  const pastEvents = webinarData?.filter((item: any) => {
    const endDate = new Date(item?.endDate);
    return endDate < new Date();
  });

  const upCommingEvents = webinarData?.filter((item: any) => {
    const endDate = new Date(item?.endDate);
    return endDate > new Date();
  });

  const [index, setIndex] = useState(0);
  const lastIndex = useMemo(
    () => index === upCommingEvents.length - 1,
    [index]
  );
  const firstIndex = useMemo(() => index === 0, [index]);

  const handleNext = () => {
    if (lastIndex) {
      return;
    }
    setIndex(index === upCommingEvents.length - 1 ? 0 : index + 1);
  };

  const handlePrev = () => {
    if (firstIndex) {
      return;
    }
    setIndex(index === 0 ? upCommingEvents.length - 1 : index - 1);
  };

  return (
    <div ref={sectionRef} className={`pt-16 lg:pt-24 md:pb-22 ${bgGradient}`}>
      <div className="max-w-8xl mx-auto px-6 sm:px-12 lg:px-4">
        <Header variant={HeaderVariant.h2} className="!mb-0">
          <span
            className={`block text-4xl font-semibold mb-6 md:font-bold md:mb-3 md:text-5xl ${color}`}
          >
            {title}
          </span>
        </Header>
        {/* <Link
          to="/webinars/test-perpetua-masterclass:-external-search-session-title"
          className="text-indigo-1 font-semibold text-base"
        >
          View all
        </Link> */}
        {upCommingEvents && upCommingEvents.length > 0 && (
          <div className="flex flex-row justify-between items-center">
            <p className={`text-3xl font-semibold mt-12 ${color}`}>
              Upcoming webinars
            </p>
            <div className="flex gap-4">
              <button
                className={cn(
                  "text-3xl text-indigo-1 border rounded-full border-indigo-1 p-3",
                  firstIndex && "opacity-50"
                )}
                onClick={handlePrev}
              >
                <RightArrow className={"transform -scale-x-100"} />
              </button>
              <button
                className={cn(
                  "text-3xl text-indigo-1 border rounded-full border-indigo-1 p-3",
                  lastIndex && "opacity-50"
                )}
                onClick={handleNext}
              >
                <RightArrow />
              </button>
            </div>
          </div>
        )}
      </div>
      {upCommingEvents && upCommingEvents.length > 0 && (
        <div className="py-10 grid grid-cols-1 lg:grid-cols-2 items-center max-w-8xl px-6 sm:px-12 lg:px-4 mx-auto">
          <div className="lg:pr-12">
            <img src={upCommingEvents[index]?.headerLogoUrl} alt="" />
          </div>
          <div className="mt-8 lg:mt-0">
            <Header variant={HeaderVariant.h4}>
              {upCommingEvents[index]?.name}
            </Header>
            <DateAndTime date={upCommingEvents[index]?.startDate} />

            <TargetAndSeries category={upCommingEvents[index]?.category} />

            <Paragraph className="text-xl mx-auto font-medium text-gray-3-dark my-8">
              {upCommingEvents[index]?.description.substring(0, 200) + "..."}
            </Paragraph>
            <Button
              id={""}
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              text={"Register"}
              onClick={() => {
                helpers
                  .delayTrack(Name.Cta, {
                    category: Category.ButtonClick,
                    type: Type.Button,
                    text: "Register",
                    page_url: location.href,
                    destination: webinarSlug(upCommingEvents[index]?.name),
                    location: ""
                  })
                  .then(() => {
                    nav(webinarSlug(upCommingEvents[index]?.name));
                  });
              }}
            />
          </div>
        </div>
      )}

      <WatchOnDemand pastEvents={pastEvents} color={color} />
    </div>
  );
};

export default WebinarSeries;
