import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { useSegment } from "../../../utils/analytics";
import { Category, Name, Type } from "../../../utils/analytics/constants";
import { nav } from "../../../utils/navigation";
import Icon, { IconType } from "../../Icon";
import DateAndTime from "../../DateAndTime";
import { webinarSlug } from "../../../utils/webinars";

interface UpComingWebinarProps {
  data: any;
}

const UpComingWebinar: React.FC<UpComingWebinarProps> = props => {
  const { data } = props;
  const { helpers } = useSegment();

  return (
    <div className="md:pb-22 bg-gradient-to-t from-indigo-3-light rounded-br-large">
      <div className="pt-10 grid grid-cols-1 lg:grid-cols-2 items-center max-w-8xl px-6 sm:px-12 lg:px-4 mx-auto">
        <div className="lg:pr-12 mb-8 lg:mb:0">
          <Header variant={HeaderVariant.h1}>
            <span className="block text-4xl font-semibold mb-6 md:font-bold md:mb-8 md:text-5xl">
              {data?.name}
            </span>
          </Header>

          <DateAndTime date={data?.startDate} />
          <div className="flex gap-3 mt-8">
            {data?.category &&
              data?.category.map((item: any) => {
                if (item?.split(":")[0] === "target") {
                  return (
                    <div className="text-base capitalize flex flex-row items-center font-medium text-gray-3-dark rounded-3xl bg-white shadow-md px-3 py-1">
                      <Icon className="mr-3" iconType={IconType.amazon} />
                      {item?.split(":")[1]}
                    </div>
                  );
                } else if (item?.split(":")[0] === "series") {
                  return (
                    <div className="text-base capitalize flex flex-row items-center font-medium rounded-3xl bg-white shadow-md px-3 py-1 border border-indigo-1-dark text-indigo-1-dark">
                      {item?.split(":")[1]}
                    </div>
                  );
                }
              })}
          </div>

          <Paragraph className="text-xl mx-auto font-medium text-gray-3-dark my-8">
            {data?.description.substring(0, 200) + "..."}
          </Paragraph>
          <Button
            id={""}
            variant={ButtonVariant.primary}
            size={ButtonSize.large}
            text={"Register"}
            onClick={() => {
              helpers
                .delayTrack(Name.Cta, {
                  category: Category.ButtonClick,
                  type: Type.Button,
                  text: "Register",
                  page_url: location.href,
                  destination: webinarSlug(data?.name),
                  location: ""
                })
                .then(() => {
                  nav(
                    webinarSlug(data?.name)
                  );
                });
            }}
          />
        </div>
        <div>
          <img src={data?.headerLogoUrl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default UpComingWebinar;
