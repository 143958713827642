import React from "react";
import Icon, { IconType } from "../../Icon";
import { data } from "../../../pages/amc-amazon-marketing-cloud";
import { nav } from "../../../utils/navigation";
import { webinarSlug } from "../../../utils/webinars";
import Button, { ButtonVariant, ButtonSize } from "../../Button";

interface WatchOnDemandProps {
  pastEvents?: any;
  color?: string;
}

const WatchOnDemand: React.FC<WatchOnDemandProps> = props => {
  const { pastEvents, color } = props;

  return (
    <div className="max-w-8xl px-6 sm:px-12 lg:px-4 mx-auto">
      <p className={`text-3xl font-semibold ${color}`}>Watch on-demand</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-20 mt-8">
        {pastEvents.map((item: any, index: number) => {
          return (
            <div key={index}>
              <img src={item?.headerLogoUrl} alt={item?.name} />
              <p className="font-semibold my-4">{item?.name}</p>
              <div className="flex gap-3">
                {item?.category &&
                  item?.category.map((category: any) => {
                    if (category?.split(":")[0] === "target") {
                      return (
                        <div className="text-base capitalize flex flex-row items-center font-medium text-gray-3-dark rounded-3xl bg-white shadow-md px-3 py-1">
                          <Icon className="mr-3" iconType={IconType.amazon} />
                          {category?.split(":")[1]}
                        </div>
                      );
                    } else if (category?.split(":")[0] === "series") {
                      return (
                        <div className="text-base capitalize flex flex-row items-center font-medium rounded-3xl bg-white shadow-md px-3 py-1 border border-indigo-1-dark text-indigo-1-dark">
                          {category?.split(":")[1]}
                        </div>
                      );
                    }
                  })}
              </div>
              <p className="my-4">
                {item.description.substring(0, 200) + "..."}
              </p>
              <Button
                id={""}
                variant={ButtonVariant.primary}
                size={ButtonSize.large}
                text={"Watch On-Demand"}
                onClick={() => {
                  nav(webinarSlug(item?.name));
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WatchOnDemand;
